import { FC } from "react";
import styles from './address-project-display.module.scss';

type AddressProjectDisplayProps = {
  addressProjectTitle: string;
  address: {
    street: string;
    streetNumber: string;
    city: string;
    zipCode: string;
    countryCode: string;
  };
  onClick: () => void;
}

export const AddressProjectDisplay: FC<AddressProjectDisplayProps> = ({ addressProjectTitle, address, onClick }) => {
  return (
    <div className={styles.addressProjectDisplay} onClick={onClick}>
      <h3 className={styles.title}>{addressProjectTitle}</h3>
      <p>{address.street} {address.streetNumber}, {address.zipCode} {address.city}, {address.countryCode}</p>
    </div>
  );
}
