import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import {
  FontSecondaryRegular,
  FontSecondarySemiBold,
  Icon,
  mediaQueries,
  spacingAround,
  spacingBottom,
  spacingCalculator,
  spacingLeft,
  spacingTop,
} from '@digando/react-component-library';
import Link from 'next/link';
import { ContentContainerMax } from '../../../layout/container';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { isFeatureFlagEnabled } from '../../../lib/environment';

interface INavEntry {
  page: string;
  asPath: string;
  title: string;
}

export const navEntries: INavEntry[] = [
  {
    page: '/organization/bookings',
    asPath: '/organisation/buchungen',
    title: 'Buchungen',
  },
  {
    page: '/organization/addresses',
    asPath: '/organisation/adressen',
    title: isFeatureFlagEnabled('isExternallyManagedOrganization') ? 'Projekte verwalten' : 'Adressen verwalten',
  },
];

// The following nav entries are not useful/not working if the users/organization is externally managed, so we'll skip them in that case.
if (!isFeatureFlagEnabled('isExternallyManagedOrganization')) {
  const additionalNavEntries = [
    {
      page: '/organization/members',
      asPath: '/organisation/nutzerverwaltung',
      title: 'Nutzerverwaltung',
    },
    {
      page: '/profile/settings',
      asPath: '/profil/einstellungen',
      title: 'Persönliche Daten',
    },
    {
      page: '/profile/password',
      asPath: '/profil/passwort',
      title: 'Passwort ändern',
    },
  ];

  navEntries.push(...additionalNavEntries);
}

const SidebarNavigation: FC = () => {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState<boolean>(false);
  const [activeNavEntry, setActiveNavEntry] = useState<INavEntry>(navEntries[0]);
  const [t] = useTranslation(['common', 'profile']);
  const router = useRouter();

  useEffect(() => {
    const newActive = navEntries.find(entry => entry.asPath === router.asPath);

    if (newActive) {
      setActiveNavEntry(newActive);
    }

    setIsMobileNavOpen(false);
  }, [router.asPath]);

  const toggleNavigation = (): void => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };

  return (
    <SidebarNavigationContainer>
      <MobileNavigationChooser onClick={(): void => toggleNavigation()}>
        {activeNavEntry.title}
        <MobileNavigationChooserIcon isOpen={isMobileNavOpen}>
          <Icon size={'40px'} icon={'dropdown'} color={`var(--white)`}/>
        </MobileNavigationChooserIcon>
      </MobileNavigationChooser>

      <Navigation isOpen={isMobileNavOpen}>
        {navEntries.map((entry, index) => {
          return (
            <NavigationItem isActive={activeNavEntry.page === entry.page} key={`nav-entry-${index}`}>
              <Link href={entry.asPath} prefetch={false} legacyBehavior>
                {entry.title}
              </Link>
            </NavigationItem>
          );
        })}
      </Navigation>

      {isFeatureFlagEnabled('isSupportEnabled') && (
        <Support>
          <SupportTitle>{t('profile:contact.title')}</SupportTitle>
          <Contact>
            <Icon icon={'phone'} size={'24px'}/>
            <ContactText>
              {t('support-hotline')} <a href={'tel:' + t('support-phone')}>{t('support-phone')}</a><br/>
            </ContactText>
          </Contact>

          <Contact>
            <Icon icon={'faq'} size={'24px'}/>
            <ContactText>
              <Link href={'/faq'}>
                {t('profile:contact.faq-link')}
              </Link>
            </ContactText>
          </Contact>
        </Support>
      )}

    </SidebarNavigationContainer>
  );
};

const SidebarNavigationContainer = styled.div`
  grid-column: auto / span 12;
  background-color: var(--secondary-color);
  margin-bottom: ${spacingCalculator(7)};

  @media ${mediaQueries.tablet} {
    margin-bottom: ${spacingCalculator(10)};
    grid-column: auto / span 3;
    background-color: transparent;
  }
`;

const MobileNavigationChooser = styled(ContentContainerMax)`
  position: relative;
  ${FontSecondarySemiBold};
  font-size: 28px;
  border-bottom: 2px solid var(--white);
  color: var(--white);
  cursor: pointer;
  margin-bottom: ${spacingCalculator(2)};
  white-space: nowrap;
  height: 44px;
  line-height: 44px;

  @media ${mediaQueries.tablet} {
    display: none;
  }
`;

const MobileNavigationChooserIcon = styled.span<INavigationProps>`
    position: absolute;
    right: 0;
    font-size: var(--font-size-headline-h4);

    transform: rotate(${(p): string => p.isOpen ? '0' : '270deg'})
`;

interface INavigationProps {
  isOpen: boolean;
}

const Navigation = styled.div<INavigationProps>`
  position: absolute;
  left: 0;
  z-index: 20;
  width: 100%;
  background-color: var(--secondary-color);
  max-height: ${(p: INavigationProps): string => p.isOpen ? '100vh' : '0'};
  transition: max-height 0.3s ease;
  overflow: hidden;

  @media ${mediaQueries.tablet} {
    position: relative;
    max-height: none;
    background-color: transparent;
    border-top: 1px solid var(--border-light-color);
  }
`;

const Support = styled.div`
  display: none;
  ${spacingTop(6)};

  @media ${mediaQueries.tablet} {
    display: block;
  }
`;

const SupportTitle = styled.span`
    display: block;
    ${spacingBottom(3)};
    ${FontSecondaryRegular};
    font-size: var(--font-size-headline-h4);
    line-height: 24px;
`;

const Contact = styled.div`
  display: flex;
  ${spacingBottom(3)};
`;

const ContactText = styled.div`
  display: inline-block;
  vertical-align: middle;
  font-size: var(--font-size-text);
  line-height: 22px;
  color: var(--black);
  ${spacingLeft(1)};

  a {
    color: inherit;
    font: inherit;
    text-decoration: none;

    &:hover {
      color: var(--primary-color);
    }
  }
`;

interface INavigationItemProps {
  isActive: boolean;
}

const NavigationItem = styled.div<INavigationItemProps>`
    ${spacingAround(2)};

    @media ${mediaQueries.tablet} {
        border-bottom: 1px solid var(--border-light-color);
    }

    a {
        display: block;
        color: var(--white);
        font-size: var(--font-size-headline-h4);
        ${(p: INavigationItemProps) => p.isActive ? FontSecondarySemiBold : FontSecondaryRegular};

        &:hover {
            color: var(--primary-color);

            @media ${mediaQueries.tablet} {
                color: var(--secondary-color);
            }
        }

        @media ${mediaQueries.tablet} {
            color: ${(p: INavigationItemProps): string => p.isActive ? `var(--secondary-color)` : `var(--black)`};
        }
    }
`;

export default SidebarNavigation;
