import { FC } from 'react';
import { meUserState } from '../../../../store/recoil/auth.store';
import { useNavigationContext } from '../../../../store/navigation.context';
import { navEntries } from '../../../profile/components/sidebar-navigation';
import { Icon } from '@digando/react-component-library';
import { useTranslation } from 'next-i18next';
import { useRecoilValue } from 'recoil';
import { signOut } from 'next-auth/react';
import Link from 'next/link';
import styles from './profile-item.module.scss';
import { isFeatureFlagEnabled } from '../../../../lib/environment';

type LoggedInMenuProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export const LoggedInMenu: FC<LoggedInMenuProps> = ({isOpen, setIsOpen}) => {
  const [t] = useTranslation(['common']);
  const meUser = useRecoilValue(meUserState);
  const { setIsUserDropdownMenuVisible, isUserDropdownMenuVisible } = useNavigationContext();

  const doLogout = (): void => {
    const callbackUrl = isFeatureFlagEnabled('isPrivatePlatform') ? '/sso-logout' : '/';

    signOut({ callbackUrl });
    setIsOpen(false);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <ul id={'page-header-profile-menu'} className={styles.loggedInMenu} onClick={(): void => setIsUserDropdownMenuVisible(!isUserDropdownMenuVisible)}>
        {navEntries.map((entry, index) => (
          <li key={entry.asPath} onClick={() => setIsOpen(false)}>
            <Link href={entry.page} as={entry.asPath} prefetch={false} legacyBehavior>
              {entry.title}
            </Link>
          </li>
        ))}

        <li key={'logout'}>
          <a className={styles.logoutButton} onClick={doLogout}>
            <span className={styles.userContainer}>
              <span className={styles.logout}>
                {t('common:sign-out')}
              </span>
              <span className={styles.user}>
                {meUser?.firstName} {meUser?.lastName}
              </span>
            </span>
            <Icon icon={'logout'} size={'30px'} />
          </a>
        </li>
      </ul>
    </>
  );
};
